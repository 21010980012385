// frontend/src/components/Post.jsx
import React, { forwardRef } from "react";
import Box from "@mui/material/Box";

import VideoPlayer from "./AppItems/AppPlayer2";
import LikeButton from "./LikeButton";
import CommentsDrawer from "./CommentsDrawer";
import PostDetails from "./PostDetails";
import MoreOptions from "./MoreOptions";

const styles = {
  container: {
    position: "relative",
    height: "100dvh",
    scrollSnapAlign: "start",
  },
  postDetails: {
    position: "absolute",
    bottom: 70,
    left: 10,
    width: "80%",
  },
  interactionContainer: {
    position: "absolute",
    right: 5,
    bottom: "16%",
    display: "flex",
    flexDirection: "column",
  },
};

const Post = forwardRef(
  ({ videoSrc, username, postContent, postId, likeCount, metadata }, ref) => {
    return (
      <Box ref={ref} sx={styles.container}>
        <VideoPlayer src={videoSrc} postId={postId} metadata={metadata} />
        <Box sx={styles.postDetails}>
          <PostDetails username={username} postContent={postContent} />
        </Box>
        <Box sx={styles.interactionContainer}>
          <LikeButton likeCount={likeCount} postId={postId} />
          <CommentsDrawer postId={postId} />
          <MoreOptions
            options={[
              { text: "Report", link: `/report/${postId}` },
              { text: "Share", link: `/share/${postId}` },
            ]}
          />
        </Box>
      </Box>
    );
  }
);

export default Post;
